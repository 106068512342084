* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;

  @media (max-width: 600px) {
    font-size: 50%;
  }
}

body {
  font-family: "Roboto", sans-serif !important;
}

.App-header {
  height: 60rem;
  width: 100vw;
  background-image: linear-gradient(rgb(14, 1, 1), rgb(36, 54, 82));
  background-size: cover;
  clip-path: polygon(0 0, 100% 0, 100% 60%, 0 100%);
  text-align: center;
  position: absolute;
  z-index: 1000;
}

.layer2 {
  height: 60rem;
  background-image: linear-gradient(rgb(8, 7, 77), rgb(2, 9, 20));
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 70%);
}

.title {
  font-size: 2.4rem;
  color: rgb(223, 224, 233);
  font-weight: 300;
  padding: 3rem;
  animation: moveInLeft 0.8s ease-out;
}
.desc {
  font-size: 1.8 rem;
  color: rgb(223, 224, 233);
  font-weight: 300;
  animation: moveInRight 0.8s ease-out;
}

.body {
  background-color: rgb(223, 224, 233);
  height: 80rem;
  text-align: center;
  margin-top: -20rem;
}
.logo {
  z-index: 1000;
  height: 15vh;
  width: 15vh;
}
@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.btn {
  display: inline-block;
  background-color: rgb(36, 54, 82);
  text-align: center  ;
  font-size: 250%;
  padding: 4.2rem;
  color: rgb(223, 224, 233);
  height: 14r em;
  width: 21rem;
  margin: 15rem;
  border-radius: 10px;
  transition: all 0.2s;
}

.btn:hover {
  background-color: rgb(20, 19, 43);
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.footer {
  height: 20rem;
  background-color: rgb(2, 9, 20);
}

.list {
  height: 5vh;

  text-align: center;
}
li {
  border-top: 1px solid rgb(223, 224, 233);
  display: inline-block;
  padding: 3.7rem;
  margin-top: 4rem;
  font-size: 1.8rem;
}

a:link,
a:visited {
  text-decoration: none;
  text-transform: uppercase;
  color: rgb(223, 224, 233);
}

.TBD {
  font-size: 5rem;
  color: rgb(36, 54, 82);

}
